import * as React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './modules/home-page/HomePage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
}

export default App;
