import React, { useState } from 'react';
import styles from './Navigation.module.scss';
const Navigation = ({ open }) => {
  const [pageType, setPageType] = useState('about_us');

  const handleChangePageType = (type) => {
    setPageType(type);
  };

  return (
    <>
      <div className={open ? styles.openedNavigationHolder : styles.closedNavigationHolder}>
        <ul className={styles.navListHolder}>
          <li className={styles.navListItem}>
            <button
              className={pageType === 'about_us' ? styles.navButtonActive : styles.navButton}
              onClick={() => handleChangePageType('about_us')}
            >
              About us
            </button>
          </li>
          <li className={styles.navListItem}>
            <button
              className={pageType === 'our_projects' ? styles.navButtonActive : styles.navButton}
              onClick={() => handleChangePageType('our_projects')}
            >
              Projects
            </button>
          </li>
          <li className={styles.navListItem}>
            <button className={styles.navButton}>
              <a href="mailto: info@eightfoldworld.com" className={styles.mailToLink}>
                Contact us
              </a>
            </button>
          </li>
        </ul>
        <div className={styles.divider} />
        {pageType === 'our_projects' ? (
          <div className={styles.projectLinksHolder}>
            <p>
              <a
                href="https://eightfoldcolombia.com/index.html"
                rel="noopener noreferrer"
                target="_blank"
                className={styles.projectLinks}
              >
                Eightfold Colombia
              </a>
            </p>
            <p>
              <a
                href="https://eightfoldmountainvillage.com"
                rel="noopener noreferrer"
                target="_blank"
                className={styles.projectLinks}
              >
                Eightfold Mountain Village
              </a>
            </p>
            <p>
              <a
                href="https://www.figma.com/proto/o2pQeDICMK3idpCGTLNZlI/Untitled?type=design&node-id=112-359&t=mr1NDgu66f4zaVMq-1&scaling=min-zoom&page-id=1%3A2179&starting-point-node-id=112%3A359&mode=design"
                rel="noopener noreferrer"
                target="_blank"
                className={styles.projectLinks}
              >
                Biodiversity Bank
              </a>
            </p>
          </div>
        ) : (
          <div className={styles.infoHolder}>
            <p className={styles.info}>
              EIGHTFOLD is a pioneering company that encompasses science, biotechnology, art, personal development and
              creative expression.{' '}
            </p>
            <p className={styles.info}>
              Our projects are dedicated to preserving natural landscapes, promoting green initiatives, fostering
              biodiversity, and creating a sustainable community.
              Following the time-honoured traditions, we combine non-invasive approaches to impacting flora and fauna
              with advanced organic farming methods.
            </p>
            <p className={styles.info}>
              Contact us for opportunities to learn more about projects, volunteering, partnerships, or ways to
              contribute.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Navigation;
