import * as React from 'react';
import styles from './HomePage.module.scss';
import videoURL from '../../assets/videos/mainvideo.mp4';

import Header from '../../common/header/Header';
import { useState } from 'react';

const HomePage = () => {
  const [muted, setSound] = useState(true);
  const [open, setOpen] = useState(false);

  const handleChangeSoundOff = () => {
    setSound(true);
  };

  const handleChangeSoundOn = () => {
    setSound(false);
  };

  const handleSetOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.holder}>
      <video preload="auto" loop autoPlay playsInline muted={muted}>
        <source src={videoURL} type="video/mp4" />
      </video>
      <Header
        handleChangeSoundOff={handleChangeSoundOff}
        handleChangeSoundOn={handleChangeSoundOn}
        open={open}
        setOpen={handleSetOpen}
        muted={muted}
      />
    </div>
  );
};

export default HomePage;
