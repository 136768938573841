import * as React from 'react';
import '@fontsource/inter';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import styles from './Header.module.scss';
import logo from '../../assets/images/logo.svg';
import Navigation from '../navigation/Navigation';

const StyledDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 3rem;
  height: 2rem;
  margin-top: 20px;
  cursor: pointer;
`;

const Header = ({ handleChangeSoundOff, handleChangeSoundOn, open, setOpen, muted }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 451px)' });

  return (
    <div className={open ? styles.openedHeader : styles.closedHeader}>
      {isMobile ? (
        <div className={open ? styles.headerIconsHolderActive : styles.headerIconsHolder}>
          <img src={logo} className={open ? styles.logoHided : styles.logo} alt="" />
          <StyledDiv open={open} onClick={setOpen}>
            <div className={open ? styles.burgerLineOneOpened : styles.burgerLineOneClosed} />
            <div className={open ? styles.burgerLineTwoOpened : styles.burgerLineTwoClosed} />
          </StyledDiv>
          {open ? (
            <div className={styles.soundButtonsHolderHided}>
              <p className={styles.soundButtonsText}>SOUND</p>
              <div>
                <button
                  className={muted ? styles.soundButtonActive : styles.soundButton}
                  onClick={(e) => handleChangeSoundOff(e)}
                >
                  OFF
                </button>
                <span className={styles.soundButtonsTextSpan}>/</span>
                <button
                  className={muted ? styles.soundButton : styles.soundButtonActive}
                  onClick={(e) => handleChangeSoundOn(e)}
                >
                  ON
                </button>
              </div>
            </div>
          ) : (
            <div className={styles.soundButtonsHolder}>
              <p className={styles.soundButtonsText}>SOUND</p>
              <div>
                <button
                  className={muted ? styles.soundButtonActive : styles.soundButton}
                  onClick={(e) => handleChangeSoundOff(e)}
                >
                  OFF
                </button>
                <span className={styles.soundButtonsTextSpan}>/</span>
                <button
                  className={muted ? styles.soundButton : styles.soundButtonActive}
                  onClick={(e) => handleChangeSoundOn(e)}
                >
                  ON
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={open ? styles.headerIconsHolderActive : styles.headerIconsHolder}>
          <img src={logo} className={styles.logo} alt="" />
          <StyledDiv open={open} onClick={setOpen}>
            <div className={open ? styles.burgerLineOneOpened : styles.burgerLineOneClosed} />
            <div className={open ? styles.burgerLineTwoOpened : styles.burgerLineTwoClosed} />
          </StyledDiv>
          <div className={styles.soundButtonsHolder}>
            <p className={styles.soundButtonsText}>SOUND</p>
            <div>
              <button
                className={muted ? styles.soundButtonActive : styles.soundButton}
                onClick={(e) => handleChangeSoundOff(e)}
              >
                OFF
              </button>
              <span className={styles.soundButtonsTextSpan}>/</span>
              <button
                className={muted ? styles.soundButton : styles.soundButtonActive}
                onClick={(e) => handleChangeSoundOn(e)}
              >
                ON
              </button>
            </div>
          </div>
        </div>
      )}

      {open && <Navigation open={open} />}
    </div>
  );
};

export default Header;
